import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useParams } from 'react-router-dom';
import { matchPath } from 'react-router';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';

import './css/library/bootstrap.min.css';
import './css/banner.css';
import './css/body.css';
import './css/content.css';
import './css/footer.css';
import './css/loading.css';
import './css/navbar.css';
import './css/masonry.css';

import { PageAbout, PageArticle, PageArticleOverview, PageDisclaimer, PageHomepage, PageHowwework, PageOnboarding, PagePrivacy, PageVacancy, PageVacancyOverview } from './components/Page';

import { SectionNavigation, SectionFooter } from './components/Section';

import { ElementDebugResponsive } from './components/Element';

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export default function App()
{
    // Debug
    const debug = useRef(false);

    // IP address
    const ip_address = useRef('https://thissen.ai');
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    {
        ip_address.current = (window.location.origin).replace('3006', '8008');
        console.log('IP: '+ip_address.current);

        debug.current = true;
    }

    // Translations
    const [translation_language, translation_set_language] = useState(null);
    const [translation_data, translation_set_data] = useState([]);

    function AppTranslations(props)
    {
        const {language} = useParams();

        useEffect(() => {
            if (translation_language === null || translation_language !== language)
            {
                const get = async() => {
                    try
                    {
                        let params = {
                            language: language
                        };

                        const response = await axios.get(ip_address.current+'/backend/financeteams/api/translations', { params });
                        
                        // Build dictionary
                        let dict = {}
                        for (let i = 0; i < response.data.length; i++)
                        {
                            if (response.data[i][1] !== '')
                                dict[response.data[i][0]] = response.data[i][1];
                        }

                        translation_set_language(language);
                        translation_set_data(dict);

                        if (debug.current === true)
                            console.log(response.data);
                    }

                    catch (error)
                    {
                        console.log(error);
                    }
                }

                get();
            }
        }, [language]);

        return null;
    }
    
    // Metatag
    const [metatag_data, metatag_set_data] = useState(null);
    useEffect(() => {
        const get = async() => {
            try
            {
                const response = await axios.get(ip_address.current+'/backend/financeteams/api/metatag');
                // <str> url
                // <str> meta_title
                // <str> meta_description
                
                metatag_set_data(response.data);
                
                if (debug.current === true)
                    console.log(response.data);
            }

            catch (error)
            {
                console.log(error);
            }
        }
        
        get();
    }, []);

    const [meta_title, meta_set_title] = useState('');
    const [meta_description, meta_set_description] = useState('');
    
    function AppMetatag()
    {
        const location = useLocation();
        
        useEffect(() => {
            if (metatag_data !== null)
            {
                for (let i = 0; i < metatag_data.length; i++)
                {
                    let match = matchPath({path:metatag_data[i][0], strict:true}, location['pathname']);
                    if (match !== null)
                    {
                        meta_set_title(metatag_data[i][1]);
                        meta_set_description(metatag_data[i][2]);

                        if (debug.current === true)
                        {
                            console.log('Meta title: '+metatag_data[i][1]);
                            console.log('Meta description: '+metatag_data[i][2]);
                        }

                        break;
                    }
                }
            }
        }, [location]);
        
        return null;
    }

    // Upload images
    const upload_images = useRef('https://financeteams.com/uploads');

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
    {
        upload_images.current = 'http://localhost:8008/uploads';

        if (debug.current === true)
            console.log('Upload folder: '+upload_images.current);
    }
    
    // Scroll to top
    function AppScrollToTop()
    {
        const location = useLocation();

        useEffect(() => {
            if (location['search'] === '' && location['hash'] === '')
            {
                window.scrollTo({
                    top: 0,
                    behavior: 'instant',
                });
            }
        }, [location]);
        
        return null;
    }
    
    // Routes
    const routes = [];
    const routes_urls = {
        'nl-NL': {},
        'en-GB': {},
        'bs-BA': {}
    };

    function routes_add(url_key, url_dict, page)
    {
        let key = 0;
        for (let language in url_dict)
        {
            routes.push(
                <Route key={key} exact path={url_dict[language]} element={
                    <React.Fragment>
                        <AppTranslations
                            translation_language={translation_language} />
                        <AppMetatag />

                        {page}
                    </React.Fragment>
                } />
            );
            
            let url_split = url_dict[language].split('/', 1)[0];
            routes_urls[language][url_key] = url_split;
            
            key++;
        }
    }
    
    routes_add('about', {
        'nl-NL': 'over',
        'en-GB': 'about',
        'bs-BA': 'o-nama'
    }, (
        <PageAbout
            debug={debug.current}
            ip_address={ip_address.current}
            routes_urls={routes_urls}
            translation_data={translation_data}
            translation_language={translation_language}
            upload_images={upload_images.current} />
    ));

    routes_add('article', {
        'nl-NL': 'artikel/:article_slug',
        'en-GB': 'article/:article_slug',
        'bs-BA': 'clanak/:article_slug'
    }, (
        <PageArticle
            debug={debug.current}
            ip_address={ip_address.current}
            routes_urls={routes_urls}
            translation_data={translation_data}
            translation_language={translation_language}
            upload_images={upload_images.current} />
    ));

    routes_add('article_overview', {
        'nl-NL': 'artikelen',
        'en-GB': 'articles',
        'bs-BA': 'clanci'
    }, (
        <PageArticleOverview
            debug={debug.current}
            ip_address={ip_address.current}
            routes_urls={routes_urls}
            translation_data={translation_data}
            translation_language={translation_language}
            upload_images={upload_images.current} />
    ));

    routes_add('disclaimer', {
        'nl-NL': 'vrijwaring',
        'en-GB': 'disclaimer',
        'bs-BA': 'odricanje-od-odgovornosti'
    }, (
        <PageDisclaimer
            debug={debug.current}
            ip_address={ip_address.current}
            routes_urls={routes_urls}
            translation_data={translation_data}
            translation_language={translation_language} />
    ));
    
    routes_add('home', {
        'nl-NL': '',
        'en-GB': '',
        'bs-BA': ''
    }, (
        <PageHomepage
            debug={debug.current}
            ip_address={ip_address.current}
            routes_urls={routes_urls}
            translation_data={translation_data}
            translation_language={translation_language}
            upload_images={upload_images.current} />
    ));
    
    routes_add('howwework', {
        'nl-NL': 'hoe-wij-werken',
        'en-GB': 'how-we-work',
        'bs-BA': 'kako-radimo'
    }, (
        <PageHowwework
            debug={debug.current}
            ip_address={ip_address.current}
            routes_urls={routes_urls}
            translation_data={translation_data}
            translation_language={translation_language} />
    ));
    
    routes_add('onboarding', {
        'nl-NL': 'onboarding',
        'en-GB': 'onboarding',
        'bs-BA': 'onboarding'
    }, (
        <PageOnboarding
            debug={debug.current}
            ip_address={ip_address.current}
            routes_urls={routes_urls}
            translation_data={translation_data}
            translation_language={translation_language} />
    ));
    
    routes_add('privacy', {
        'nl-NL': 'privacyverklaring',
        'en-GB': 'privacy-statement',
        'bs-BA': 'politika-privatnosti'
    }, (
        <PagePrivacy
            debug={debug.current}
            ip_address={ip_address.current}
            routes_urls={routes_urls}
            translation_data={translation_data}
            translation_language={translation_language} />
    ));
    
    routes_add('vacancy', {
        'nl-NL': 'vacature/:vacancy_slug',
        'en-GB': 'vacancy/:vacancy_slug',
        'bs-BA': 'otvorena-pozicija/:vacancy_slug'
    }, (
        <PageVacancy
            debug={debug.current}
            ip_address={ip_address.current}
            routes_urls={routes_urls}
            translation_data={translation_data}
            translation_language={translation_language} />
    ));
    
    routes_add('vacancy_overview', {
        'nl-NL': 'vacatures',
        'en-GB': 'careers',
        'bs-BA': 'karijera'
    }, (
        <PageVacancyOverview
            debug={debug.current}
            ip_address={ip_address.current}
            routes_urls={routes_urls}
            translation_data={translation_data}
            translation_language={translation_language}
            upload_images={upload_images.current} />
    ));
    
    // Render
    return (
        <HelmetProvider>
            <Helmet>
                <title>{meta_title}</title>
                <meta name="description" content={meta_description} />
            </Helmet>
            <Router>
                <SectionNavigation
                    debug={debug.current}
                    routes_urls={routes_urls}
                    translation_data={translation_data}
                    translation_language={translation_language} />
                
                <AppScrollToTop />
                
                <Routes>
                    { /* Redirect */ }
                    <Route exact path="" element={
                        <Navigate to="/en-GB" />
                    } />
                    
                    <Route exact path=":language">
                        {routes}
                    </Route>
                    
                    { /* Redirect old website pages */ }
                    <Route exact path="/nl" element={<Navigate replace to="/nl-NL" />} />
                    <Route exact path="/nl/vind-professionals" element={<Navigate replace to="/nl-NL/hoe-wij-werken" />} />
                    <Route exact path="/nl/ons-team" element={<Navigate replace to="/nl-NL/over" />} />
                    <Route exact path="/nl/over-financeteams" element={<Navigate replace to="/nl-NL/over" />} />
                    <Route exact path="/nl/contact" element={<Navigate replace to="/nl-NL" />} />
                    <Route exact path="/nl/disclaimer" element={<Navigate replace to="/nl-NL/vrijwaring" />} />
                    <Route exact path="/over" element={<Navigate replace to="/nl-NL/over" />} />
                    <Route exact path="/contact" element={<Navigate replace to="/nl-NL" />} />
                    <Route exact path="/vind-professionals" element={<Navigate replace to="/nl-NL/hoe-wij-werken" />} />
                    <Route exact path="/ons-team" element={<Navigate replace to="/nl-NL/over" />} />
                    <Route exact path="/vind-finance-professionals" element={<Navigate replace to="/nl-NL/hoe-wij-werken" />} />
                    <Route exact path="/over-financeteams" element={<Navigate replace to="/nl-NL/over" />} />

                    <Route exact path="/en" element={<Navigate replace to="/en-GB" />} />
                    <Route exact path="/en/find-professionals" element={<Navigate replace to="/en-GB/how-we-work" />} />
                    <Route exact path="/en/our-team" element={<Navigate replace to="/en-GB/about" />} />
                    <Route exact path="/en/about-financeteams" element={<Navigate replace to="/en-GB/about" />} />
                    <Route exact path="/en/careers" element={<Navigate replace to="/en-GB/careers" />} />
                    <Route exact path="/en/contact" element={<Navigate replace to="/en-GB" />} />
                    <Route exact path="/en/disclaimer" element={<Navigate replace to="/en-GB/disclaimer" />} />
                    
                    <Route exact path="/ba" element={<Navigate replace to="/bs-BA" />} />
                    <Route exact path="/ba/pronadjite-strucnjaka" element={<Navigate replace to="/bs-BA/kako-radimo" />} />
                    <Route exact path="/ba/nas-tim" element={<Navigate replace to="/bs-BA/o-nama" />} />
                    <Route exact path="/ba/saradjujte-sa-financeteams" element={<Navigate replace to="/bs-BA/o-nama" />} />
                    <Route exact path="/ba/karijere" element={<Navigate replace to="/bs-BA/karijera" />} />
                    <Route exact path="/ba/kontakt" element={<Navigate replace to="/bs-BA" />} />
                    <Route exact path="/ba/odricanje-od-odgovornosti" element={<Navigate replace to="/bs-BA/odricanje-od-odgovornosti" />} />
                    
                    <Route exact path="/be" element={<Navigate replace to="/nl-NL" />} />
                    <Route exact path="/be/vind-professionals" element={<Navigate replace to="/nl-NL/hoe-wij-werken" />} />
                    <Route exact path="/be/ons-team" element={<Navigate replace to="/nl-NL/over" />} />
                    <Route exact path="/be/over-financeteams" element={<Navigate replace to="/nl-NL/over" />} />
                    <Route exact path="/be/contact" element={<Navigate replace to="/nl-NL" />} />
                    <Route exact path="/be/disclaimer" element={<Navigate replace to="/nl-NL/vrijwaring" />} />

                    <Route exact path="/de" element={<Navigate replace to="/en-GB" />} />
                    <Route exact path="/de/fachleute-finden" element={<Navigate replace to="/en-GB/how-we-work" />} />
                    <Route exact path="/de/unser-team" element={<Navigate replace to="/en-GB/about" />} />
                    <Route exact path="/de/uber-financeteams" element={<Navigate replace to="/en-GB/careers" />} />
                    <Route exact path="/de/kontakt" element={<Navigate replace to="/en-GB" />} />
                    <Route exact path="/de/disclaimer" element={<Navigate replace to="/en-GB/disclaimer" />} />
                    
                    { /* Redirect on not found */ }
                    <Route path="*" element={
                        <Navigate to="/" />
                    } />
                </Routes>
                
                <SectionFooter
                    debug={debug.current}
                    routes_urls={routes_urls}
                    translation_data={translation_data}
                    translation_language={translation_language} />

                { debug.current === true &&
                    <ElementDebugResponsive
                        ip_address={ip_address.current} />
                }
            </Router>
        </HelmetProvider>
    );
}

const app = ReactDOM.createRoot(document.getElementById('app'));
app.render(
    <App />
);