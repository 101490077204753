function UtilContent(content, key, debug)
{
    if (content === undefined)
    {
        if (debug === true)
            return key;
        else
            return null;
    }
    else
        return content;
}

function UtilTranslate(translations, key, debug)
{
    let translation = translations[key];

    if (debug === true)
    {
        if (translation !== undefined)
            return translation;
        else
            return key;
    }

    if (translation !== undefined)
        return translation;
    else
        return '';
}

function UtilRoute(language, routes_urls, key, slug)
{
    if (language !== null)
    {
        let url = '/'+language+'/'+routes_urls[language][key];

        if (slug !== null)
            url += '/'+slug;
        
        return url;
    }
    else
        return null;
}

export { UtilContent, UtilTranslate, UtilRoute }