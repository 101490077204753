import React from 'react';

import { SectionAbout, SectionArticle, SectionArticleOverview, SectionArticleProfiles, SectionArticleQuotes, SectionArticleRelated, SectionBanner, SectionDisclaimer, SectionFormContact, SectionFormVacancy, SectionHowwework, SectionOnboarding, SectionOnboardingIntro, SectionPrivacy, SectionMeettheteam, SectionVacancy, SectionVacancyOverview, SectionWhatwedo } from './Section';

function PageAbout(props)
{
    return (
        <div className="page">
            <SectionAbout
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language}
                upload_images={props.upload_images} />

            <SectionArticleProfiles
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language}
                upload_images={props.upload_images} />

            <SectionFormContact
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </div>
    );
}

function PageArticle(props)
{
    return (
        <div className="page">
            <SectionArticle
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language}
                upload_images={props.upload_images} />
            
            <SectionArticleRelated
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language}
                upload_images={props.upload_images} />
                
            { /*
            <SectionArticleRelated
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
            */ }

            <SectionFormContact
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </div>
    );
}

function PageArticleOverview(props)
{
    return (
        <div className="page">
            <SectionArticleOverview
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language}
                upload_images={props.upload_images} />

            <SectionFormContact
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </div>
    ); 
}

function PageDisclaimer(props)
{
    return (
        <div className="page">
            <SectionDisclaimer
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionFormContact
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </div>
    )
}

function PageHowwework(props)
{
    return (
        <div className="page">
            <SectionHowwework
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionFormContact
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </div>
    ); 
}

function PageHomepage(props)
{
    return (
        <div className="page">
            <SectionBanner
                debug={props.debug}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionWhatwedo
                translation_data={props.translation_data} />
            
            <SectionArticleQuotes
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language}
                upload_images={props.upload_images} />

            <SectionOnboardingIntro
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionMeettheteam
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionFormContact
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </div>
    );
}

function PageOnboarding(props)
{
    return (
        <div className="page">
            <SectionOnboarding
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionFormContact
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </div>
    ); 
}

function PagePrivacy(props)
{
    return (
        <div className="page">
            <SectionPrivacy
                debug={props.debug}
                ip_address={props.ip_address}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionFormContact
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </div>
    )
}

function PageVacancy(props)
{
    return (
        <div className="page">
            <SectionVacancy
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />

            <SectionFormVacancy
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </div>
    ); 
}

function PageVacancyOverview(props)
{
    return (
        <div className="page">
            <SectionVacancyOverview
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language}
                upload_images={props.upload_images} />
            
            <SectionArticleProfiles
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language}
                upload_images={props.upload_images} />

            <SectionFormContact
                debug={props.debug}
                ip_address={props.ip_address}
                routes_urls={props.routes_urls}
                translation_data={props.translation_data}
                translation_language={props.translation_language} />
        </div>
    )
}

export { PageAbout, PageArticle, PageArticleOverview, PageDisclaimer, PageHomepage, PageHowwework, PageOnboarding, PagePrivacy, PageVacancy, PageVacancyOverview };